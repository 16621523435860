.regular-button_regular-button_3698 { 
    background-color: var(--v_sdj1sksy);
    color: var(--v_ckf2c8yv);

    &:focus,
    &:hover {
        background-color: var(--v_9zabiv6c);
        color: var(--v_6z93kc2r);
    }

    &:active {
        background-color: var(--v_s5hhxeid);
        color: var(--v_6jpcba74);
    }
 } 