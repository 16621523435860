.nav-buttons-style_wrapper_4388 { 
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-self: flex-start;
    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin-left: 10px;
    
    }

    user-select: none;
 } 
.nav-buttons-style_icon_1244 { 
    width: 70px;
    height: 70px;

    
    @media (max-width: 1024px) {
        
        width: 45px;
        height: 45px;
    
    }


    margin-bottom: 16px;
    cursor: pointer;
    outline: none;

    &:focus,
    &:hover {
        svg {
            fill: var(--v_utmiq9ht) !important;
        }

        fill: var(--v_0s3rb86o) !important;
    }

    &:active {
        svg {
            fill: var(--v_f9xn080n) !important;
        }
    }
 } 
.nav-buttons-style_icon-close_1816 { 
    fill: rgba(255, 255, 255, 0.3);
 } 