.lobby-header-style_wrapper_2250 { 
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin-top: 10px;
    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin-top: 0;
    
    }

 } 
.lobby-header-style_title_5809 { 
    text-align: right;
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--v_gbpjb7oq);
 } 
.lobby-header-style_max-players_7960 { 
    text-align: right;
    font-weight: normal;
    font-size: 0.9rem;
    color: var(--v_uuf4pzxb);
 } 
.lobby-header-style_title-icon_7331 { 
    width: 40px;
    height: 40px;
    margin-left: 12px;

    svg {
        fill: var(--v_k4d83sfp);
    }
 } 