.profile-avatar-style_wrapper_2741 { 
    display: flex;
    position: relative;
    cursor: pointer;
    outline: none;

    width: 144px;
    height: 144px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 140px;
        height: 140px;
    
    }

    
  &:focus {
        .avatar_avatar_0138 {
            border-color: var(--v_pbvup156);
        }
    }

    
    @media (hover: hover) {
        
        &:hover {
            .avatar_avatar_0138 {
                border-color: var(--v_3fa3mbai);
            }
        }
    
    }

    &:active {
        .avatar_avatar_0138 {
            border-color: var(--v_3ok2y8n1);
        }
    }

    margin-bottom: 12px;

    
 } 
.profile-avatar-style_icon_3651 { 
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;

    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;

    
    svg {
        fill: white;
        width: 18px;
        height: 18px;
        margin-left: 4px;

        
    }
 } 