.logo-nav_logo-nav_2147 { 
    display: block;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-position: center center;
    background-size: contain;
    width: 187px;
    height: 40px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 100%;
        height: 30px;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        width: 100%;
        height: 30px;
    
    }


  
 } 