.message-box-title-style_box-title-wrapper_1848 { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
 } 
.message-box-title-style_box-title_4130 { 
    font-size: 1.5rem;
 } 
.message-box-title-style_icon-title_6313 { 
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    height: 1.8rem;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        fill: white;
    }
 } 