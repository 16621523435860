.message-box-wrapper-style_wrapper_1086 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
 } 
.message-box-wrapper-style_blurred-wallpaper-base_6572 { 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: var(--v_e2cgxqdc);
 } 
.message-box-wrapper-style_blurred-wallpaper-tv_9397 { 
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
 } 
.message-box-wrapper-style_message-box-base_5172 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 1.25rem;
    font-size: 1.3rem;
    backdrop-filter: var(--v_vlfhesrx);

    max-width: 60vw;
    min-width: 40vw;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        max-width: 75vw;
    
    }

 } 
.message-box-wrapper-style_message-box-tv_4384 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 1.25rem;
    font-size: 1.3rem;
    max-width: 50vw;
 } 