.left-pan_left-pan_3863 { 
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 144px;
    margin-right: 16px;
    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 140px;
    
    }

    
 } 