.trailer-style_wrapper_6011 { 
    position: absolute;
    z-index: 901;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);

    

    display: flex;
    align-items: center;
    justify-content: center;
 } 
.trailer-style_video-wrapper_6266 { 
    position: relative;
    width: 70vw;
    height: calc(70vw / (16 / 9));

    

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 75vw;
        height: calc(75vw / (16 / 9));
    
    }

 } 
.trailer-style_close_8270 { 
    position: absolute;
    z-index: 900;
    width: 35px;
    height: 35px;
    right: -16px;
    top: -16px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 50%;
    cursor: pointer;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 30px;
        height: 30px;
        right: -12px;
        top: -10px;
    
    }

 } 
.trailer-style_video_3388 { 
    outline: none;
    width: 100%;
    height: 100%;

    background-color: #0a0a0ae8;
 } 