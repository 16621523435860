.language-box-style_message-wrapper_7733 { 
    width: 100%;
    margin-bottom: 2rem;
 } 
.language-box-style_text_6907 { 
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
 } 
.language-box-style_lang-selection-wrapper_3249 { 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -30px;
    min-width: var(--v_da20zw0f);
 } 
.language-box-style_lang-wrapper_6532 { 
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: calc(100% / 3);
 } 
.language-box-style_lang_8565 { 
    margin-left: 1rem;
 } 