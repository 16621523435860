.action-buttons-style_wrapper-base_6312 { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
        margin-right: 16px;
        margin-top: 12px;
        padding: 0.7rem 1rem;
    }
 } 
.action-buttons-style_wrapper-mobile_2518 { 
    display: flex;
    flex-direction: column;
    flex: 1;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        & > * {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            min-width: unset;
            padding: 0.6rem 0;
        }
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        & > * {
            margin-left: 6px;
            margin-right: 6px;
        }
    
    }

 } 