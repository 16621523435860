.wrapper_wrapper_3610 { 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    min-width: 436px;
    padding: 16px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        min-width: 418px;
    
    }


    
 } 