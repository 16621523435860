.remember-me-style_wrapper_5662 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
    outline: none;
    color: var(--v_pdoykczy);

    &:focus {
        color: var(--v_p9gm63d4);
        .checkbox-style_switch_7656 {
            box-shadow: 0 0 0 var(--v_0krmcypr) var(--v_m9v5u6pt);
        }
    }
 } 
.remember-me-style_text_2024 { 
    font-size: 0.75rem;
    padding-left: 8px;
 } 