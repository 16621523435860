.content-rating-style_wrapper_7758 { 
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 4px;
    padding: 12px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        padding: 8px;
    
    }


    
 } 
.content-rating-style_text_6123 { 
    font-size: 1rem;
 } 