.titles-style_wrapper_8585 { 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    user-select: none;

    max-width: 1280px;
    margin: 0 auto;

    
 } 
.titles-style_title-wrapper_5926 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 1vw;
    min-width: 0;
    max-width: calc(
        100% / var(--v_h6ex0vh6)
    ); // divided by must match max col to display

    &:first-child {
        //margin-left: 0;
    }

    &:last-child {
        //margin-right: 0;
    }

    
 } 
.titles-style_pre-title_1476 { 
    font-size: 1.1rem;
 } 
.titles-style_title_4340 { 
    font-weight: bold;
    font-size: 2.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    width: 100%;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        font-size: 1.4rem;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        font-size: 1.2rem;
    
    }

 } 