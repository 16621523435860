.view-style_left-pan_7311 { 
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    margin: 10px 20px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin: 0 24px;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin: 0 10px;
    
    }

 } 
@keyframes view-style_appear-animation-keyframes_3600 { 
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }
.view-style_right-pan_1782 { 
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 5px 20px 20px 0;

    

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        padding: 10px 10px 10px 0;
    
    }


  animation: view-style_appear-animation-keyframes_3600 500ms linear;
    
 } 