@keyframes glass-title-style_animation_3740 { 
  0% {
    opacity: 0;
    transform: translate3d(-35%, 0, 0);
  }
  10% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(105%, 0, 0);
  }
 }
.glass-title-style_wrapper-base_7700 { 
    width: 100%;
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-size: 15rem;

    //animation
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-name: glass-title-style_animation_3740;
    animation-duration: 120s;

    
    @media (max-width: 1024px) {
        
        font-size: 10rem;
    
    }


    
    @media (max-width: 824px) and (orientation: landscape) {
        
        font-size: 8rem;
    
    }

 } 
.glass-title-style_wrapper_9115 { 
    backdrop-filter: blur(6px) saturate(120%);
    background: rgba(255, 255, 255, 0.1);
    clip-path: url(#lockup-headline-mask-path);
 } 
.glass-title-style_wrapper-tv_0761 { 
    color: rgba(255, 255, 255, 0.1);
 } 
.glass-title-style_text_4033 {  } 
.glass-title-style_title_5931 { 
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
 } 
.glass-title-style_glass-title-wrapper_4905 { 
    position: absolute;
    top: -87%;
    left: 0;
    z-index: -1;
    opacity: 0;

    
    @media (max-width: 1024px) {
        
        top: -55%;
    
    }


    
  
  
    @media (max-width: 824px) and (orientation: landscape) {
        
        top: -70%;
    
    }


  
 } 
.glass_title_wrapper_css_cond_8603 { 
            opacity: 1;
         }
.glass-title-style_big-title-wrapper_5757 { 
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    

    
 } 
.big_title_wrapper_css_cond_0391 { 
            justify-content: center;
            flex-direction: column;

            .glass-title-style_glass-title-wrapper_4905 {
                top: -200px;

                
    @media (max-width: 1024px) {
        
                    top: -140px;
                
    }


                

      
    @media (max-width: 824px) and (orientation: landscape) {
        
                    top: -55%;
                
    }

            }
         }