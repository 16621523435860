.column-style_column_1705 { 
    flex: 1;
    margin: 1vw;
    max-width: calc(
        100% / var(--v_wb2ykvp9)
    ); // divided by must match max col to display

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    & > * {
        margin-bottom: 2vw;
    }
 } 