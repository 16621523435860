.action-buttons-style_wrapper-base_2727 { 
    display: flex;
    flex-direction: column;

    & > * {
        margin-top: 10px;
        padding: 0.4rem 1rem;
    }

    
    @media (max-width: 1024px) {
        
        & > * {
            margin-top: 8px;
            padding: 0.4rem 0.5rem;
            min-width: 110px;
        }
    
    }

 } 
.action-buttons-style_wrapper-mobile_8633 { 
    display: flex;
    flex-direction: column;
    flex: 1;

    .loading-button-style_text_0409 {
        padding-left: 4px;
    }

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        & > * {
            margin-top: 8px;
            min-width: 8rem;
            padding: 0.4rem 1rem;
        }
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        .loading-button-style_text_0409 {
            padding-left: 2px;
        }

        & > * {
            margin-top: 5px;
            padding: 0.4rem 0.3rem;
            min-width: 6rem;
        }
    
    }

 } 