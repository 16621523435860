.login-button-style_wrapper-base_3210 { 
    margin-top: 16px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin-top: 10px;
    
    }

 } 
.login-button-style_wrapper-tv_4543 { 
    margin-top: 25px;
 } 