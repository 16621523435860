.tctext-style_tctext-base_0310 { 
    padding: 0 28px;
    overflow-y: auto;
    text-align: justify;
    box-sizing: border-box;
    scroll-behavior: smooth;
    h1 {
        text-align: center;
        margin: 0 0 24px;
        font-size: 1.4rem;
    }
 } 
.tctext-style_tctext-responsive_1471 { 
    
    @media (max-width: 824px) and (orientation: landscape) {
        
        padding: 0 12px;
    
    }

 } 
.tctext-style_tctext-tv_6692 { 
    padding: 0 32px;
 } 