.input_input-base_7262 { 
    padding: 0.438rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    position: relative;
    color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    transition: border 250ms ease-out;
    font-size: 1rem;
    outline: none;
    min-width: 0;
    border: 2px solid transparent;

    &:hover {
        box-shadow: 0 0 0 1px var(--v_1mlp3ar0); /* emulate the border */
    }

    &:focus {
        outline: none;
        border-color: var(--v_vy0wnify);
    }

    &::placeholder {
        //color: #9f9f9f;
        color: var(--v_tcx09qgn);
    }
 } 
.input_input-tv_9533 { 
    padding: 0.52rem 0.75rem;
 } 