.bottom-buttons-style_wrapper-base_8872 { 
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        margin-right: 10px;
    }
 } 
.bottom-buttons-style_wrapper-responsive_6099 { 
    justify-content: space-between;
    margin-top: 20px;

    span {
        color: var(--v_8g172xd3);
        font-size: 0.85rem;
        text-decoration: underline;
    }

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        position: relative;
        bottom: 0;
    
    }

 } 