@keyframes game-window-style_appear-animation_3326 { 
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
 }
.game-window-style_wrapper_7969 { 
    position: absolute;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    
    }


    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-bottom: 3px;
    
    }

  
  width: 100vw;
    height: 100vh;
 } 
.game-window-style_window_0266 { 
    display: flex;
    overflow: hidden;

    width: 90vw;
    max-width: 1280px;

    --gameWindowMaxHeight: 500px;
    --gameWindowHeight: 30vw;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        --gameWindowHeight: 85vh;
    
    }


    

  

  
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 98%;
    
    }


  height: var(--gameWindowHeight);
    max-height: var(--gameWindowMaxHeight);

    animation: var(--v_3yco9n1t) 200ms linear;
 } 