.content_content_3323 { 
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-top: 26px;
    margin-bottom: 40px;

    

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin-top: 28px;
        margin-bottom: 34px;
    
    }


  
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin-top: 16px;
        margin-bottom: 16px;
    
    }

 } 