.login-box-wrapper_login-box-wrapper-base_1642 { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
    padding: 30px;
    box-sizing: border-box;
    max-width: calc(100vw - 20px);
 } 
.login-box-wrapper_login-box-wrapper-responsive_1735 { 
    
    @media (max-width: 824px) and (orientation: landscape) {
        
        width: 450px;
        padding: 15px;
    
    }

 } 
.login-box-wrapper_login-box-wrapper-tv_2913 { 
    width: 710px;
    padding: 50px;
 } 