.profile-style_swiper-slide-wrapper_0232 { 
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 } 
.profile-style_wrapper_7578 { 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    outline: none;
    user-select: none;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin: 0 0;
    
    }
;

    
 } 
.profile-style_profile-avatar_3679 { 
    width: 144px;
    height: 144px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin: var(--v_kxll8ehg);
        height: 120px;
        width: 120px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        margin: var(--v_a1n4ailp);
        width: 90px;
        height: 90px;
    
    }
;

    
    &:focus {
        border-color: var(--v_z9njqxd2);
    }

    
    @media (hover: hover) {
        
        &:hover {
            border-color: var(--v_fyk5fgb4);
        }
    
    }

    &:active {
        border-color: var(--v_pp1v8zhf);
    }
 } 
.profile-style_tag_8200 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 4px 14px;
    margin-top: 8px;
    text-align: center;
    max-width: 110%;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        padding: 2px 6px;
    
    }
;

    
 } 
.profile-style_text_5788 { 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 144px;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        max-width: 85px;
    
    }
;

    
    @media (max-width: 670px) and (orientation: landscape) {
        
        max-width: 50px;
    
    }
;

    
 } 
.profile-style_icon_2064 { 
    margin-left: 6px;
    width: 15px;
    
 } 