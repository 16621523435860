.instruction_instruction_8532 { 
    margin-top: 2px;
    margin-bottom: 22px;
    white-space: nowrap;
    color: var(--v_kphvuz7q);
    user-select: none;

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        margin-bottom: 12px;
    
    }


    
 } 