.aside-nav-style_icon_1525 { 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    outline: none;

    svg {
        fill: white;
        height: 30px;
    }

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        height: 34px;
        width: 34px;

        svg {
            height: 20px;
        }
    
    }


    
 } 
.aside-nav-style_wrapper_8572 { 
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.2);

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        height: 34px;
    
    }

    
  &:hover {
        background-color: var(--v_hjl4lu7r);
    }

    &:hover > .blur-menu-fallback-style_blur-menu-base-fallback-before_2042 {
        display: none;
    }

    

    

  
 } 
.wrapper_css_cond_7428 { 
            background-color: var(--v_0wiwcx0n);

            & > .blur-menu-fallback-style_blur-menu-base-fallback-before_2042 {
                display: none;
            }
         }
.wrapper_css_cond_5003 { 
            .aside-nav-style_settings-icon_9791 {
                background-color: var(--v_t9oiqjnu);
            }
         }
.wrapper_css_cond_8672 { 
            background-color: rgba(255, 255, 255, 0.2) !important;
         }
.aside-nav-style_settings-icon_9791 { 
    &:focus,
    &:hover {
        background-color: var(--v_nxopym5z);
    }

    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
 } 
.aside-nav-style_favorite-icon_7311 { 
    padding-left: 6px;
    padding-right: 6px;

    svg {
        fill: var(--v_f4zzhyq4);
        transition: fill 600ms ease-out;

        path {
            stroke-width: 2;
            stroke: var(--v_qovialz3);
            transition: stroke 600ms ease-out;
        }
    }

    
    @media (max-width: 824px) and (orientation: landscape) {
        
        padding-left: 4px;
        padding-right: 4px;
    
    }

    animation: game-item-style_fav-change_0303 600ms
 } 